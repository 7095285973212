import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ɵɵsetComponentScope } from '@angular/core';
import { ApiService } from '../api.service';
import * as _ from 'lodash';
import * as ApexCharts from 'apexcharts';
import { Options, ChangeContext, PointerType } from '@angular-slider/ngx-slider';
import { db } from 'src/db';
import { liveQuery } from 'dexie';
import { DataService } from '../data.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataUpdateService } from '../data-update.service';
@Component({
    selector: 'app-pyramid-chart-new-1',
    templateUrl: './pyramid-chart-new-1.component.html',
    styleUrls: ['./pyramid-chart-new-1.component.scss']
})
export class PyramidChartNew1Component implements OnInit, OnDestroy {
    spts: any[];
    locations: any[];
    skillFactor: number;

    constructor(private dataService: DataService, private dataUpdateService: DataUpdateService) { }
    pyramidData = [];
    @Input() soc: any;
    @Input() pdfExport: boolean = false;
    @Input() modal?: boolean = false;
    @Output() emitFinalSOCData: EventEmitter<any> = new EventEmitter<any>();

    data: any;
    apiData: any;
    inputData: any[];
    svg: any;
    width = 250;
    value = 10;
    height = 225;
    chart1: ApexCharts;
    chart2: ApexCharts;
    difference = 0;
    identifier: any;
    sliderOptions = { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true };
    onshore: any = { onShore: 0, offShore: 0 };
    slider = {
        level5: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 },
        level4: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 },
        level3: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 },
        level2: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 },
        level1: { value: 0, options: { floor: 0, ceil: 100, step: 1, maxLimit: 100, showSelectionBar: true }, pyramidValue: 0 }
    }
    isDefaultValuesChanged: boolean = false;

    liveQuerySubscription: Subscription = new Subscription();

    ngOnInit(): void {
        this.liveQuerySubscription.add(liveQuery(() => this.getPyramidChanges()).subscribe(async (data) => {
            this.spts = data['spts'];
            this.locations = data['locations']
            await this.reset();
        }))
    }

    ngOnDestroy(): void {
        this.liveQuerySubscription.unsubscribe();
    }

    async getPyramidChanges() {
        this.skillFactor = await this.dataService.getSkillFactor();
        this.inputData = await db.input_plot.where('tower').notEqual('').toArray();
        return await this.dataService.getByTowerSelectionChanges();
    }

    async reset() {
        this.mapDataForD3(this.soc['socs']['soc']);
        this.onshore = this.soc['offshoreData'];
        this.isDefaultValuesChanged = false;
        await this.getRatesFromAPI();
    }

    adjustPyramid(changeCon: ChangeContext, level) {
        this.adjustPyramidValues(level);
    }

    mapDataForD3(data) {
        let arrMapping = [];
        let selectedKeys = ['level5', 'level4', 'level3', 'level2', 'level1'];
        selectedKeys.forEach(sk => {
            arrMapping.push({
                name: sk,
                levelValue: data[sk]
            });
            this.slider[sk]['pyramidValue'] = data[sk];
            this.slider[sk]['value'] = data[sk];
            this.slider[sk].options = this.setNewCeil(this.slider[sk], this.slider.level1.value + this.slider[sk].value);
        });
        this.identifier = this.soc['id'] + Date.now();
        this.pyramidData = _.create(arrMapping);
        setTimeout(() => {
            this.createChart()
        }, 100);
    }

    async adjustPyramidValues(changedLevel) {
        this.pyramidData = [];
        let dbStorageObject = { soc: {} };
        Object.keys(this.slider).forEach(slider => {
            // detect which level has been changed
            dbStorageObject['soc'][slider] = this.slider[slider]['value'];
            this.pyramidData.push({
                name: slider,
                levelValue: this.slider[slider]['value']
            });
            if (slider != 'level1')
                this.slider[slider] = this.setMaxValueForLevel(this.slider[slider], changedLevel);
        });
        await this.getRatesFromAPI();
        //db.socs.update(this.soc['soc']['id'], dbStorageObject);
        // this.constructPyramid();
        this.createChart();
    }

    setMaxValueForLevel(slider, changedLevel) {
        let difference = this.slider[changedLevel]['value'] - this.slider[changedLevel]['pyramidValue'];
        if (slider.value != slider.pyramidValue && !('level1' in slider)) {
            this.slider.level1.value = this.slider.level1.value - difference;
            this.slider.level1.pyramidValue = this.slider.level1.value;
            slider.pyramidValue = slider.value;
        }
        slider.options = this.setNewCeil(slider, slider.value + this.slider.level1.value);
        return slider;
    }

    setNewCeil(slider, maxLimit) {
        const newOptions: Options = Object.assign({}, slider.options);
        newOptions['maxLimit'] = maxLimit;
        return newOptions;
    }

    // blend rate calculations start here

    async getRatesFromAPI() {
        let towerData = await db.towerRates.where({ towerId: this.soc.id }).toArray();
        this.calculateBlendRates(towerData);
    }

    calculateBlendRates(dataFromAPI) {
        dataFromAPI.forEach(element => {
            element['locationData'].forEach(location => {
                let input = this.inputData.filter(input => input.tower == element['towerId'] && input.location == location['locationId'] && input['serviceProvider'] == element['serviceProviderType']);
                if (input.length > 0)
                    location['yourRate'] = input[0]['inputValue'];
                else
                    location['yourRate'] = '-na-';

                this.calculateFinalScoreAsPerRate(location, element['serviceProviderType']);
            })
        });
        this.mergeData(dataFromAPI);
    }

    mergeData(dataFromAPI: any) {
        let data = [];
        this.locations.forEach(location => {
            try {
                this.spts.forEach(spt => {
                    let towerDataBySpt = dataFromAPI.filter(x => x.serviceProviderType == spt.id)[0]['locationData'];
                    let locationData = towerDataBySpt.filter(x => x.locationId == location.id)[0];
                    let dataToInsert = {
                        locationName: locationData.location + " (" + spt.spt.substring(0, 1) + ")",
                        locationId: locationData.locationId,
                        offshore: location.offshore,
                        sptId: spt.id,
                        sptName: spt.spt,
                        score: locationData.finalScore,
                        yourRate: locationData.yourRate
                    }
                    data.push(dataToInsert);
                })
            } catch (e) {

            }
        })
        this.data = data;
    }

    calculateFinalScoreAsPerRate(location, spt) {
        // pickup data from onshore and offshore
        // pickup data from pyramid

        Object.keys(location.rates).forEach(r => {
            if ('finalScore' in location) {
                location.finalScore = {
                    25: location.finalScore[25] + this.calculateLevelScore(location.rates[r][25], r),
                    50: location.finalScore[50] + this.calculateLevelScore(location.rates[r][50], r),
                    75: location.finalScore[75] + this.calculateLevelScore(location.rates[r][75], r)
                };
            }
            else {
                location.finalScore = {
                    25: this.calculateLevelScore(location.rates[r][25], r),
                    50: this.calculateLevelScore(location.rates[r][50], r),
                    75: this.calculateLevelScore(location.rates[r][75], r)
                };
            }
        });
        let computedSOC = {};
        _.forEach(location.rates, (rate, key) => {
            if (rate[50] != 0)
                computedSOC[key] = this.soc['socs']['soc'][key];
            else
                computedSOC[key] = 0;
        })

        let totalSOC = 0;
        _.forEach(computedSOC, (item, key) => {
            totalSOC += item;
        })
        totalSOC = totalSOC / 100;
        location.finalScore[25] = (location.finalScore[25] / totalSOC) * this.skillFactor;
        location.finalScore[50] = (location.finalScore[50] / totalSOC) * this.skillFactor;
        location.finalScore[75] = (location.finalScore[75] / totalSOC) * this.skillFactor;

        return location;
    }

    calculateLevelScore(value, level) {
        return (value * (this.slider[level]['value'] / 100) * (this.onshore.onShore) / 100)
            + (value * (this.slider[level]['value'] / 100) * (this.onshore.offShore) / 100)
    }


    getSign(data) {
        let rateString = + '&nbsp;';
        // let median = data.score[50];
        // let yourRate = data.yourRate;
        // if (yourRate && yourRate != undefined) {
        //     let percentage = ((yourRate - median) / median) * 100;
        //     if (percentage > 0)
        //         rateString = rateString + '<span style="color:#0EB787"><img src="assets/image/icon-trending up.svg">&nbsp;' + percentage.toFixed(0) + '%</span>';
        //     else if (percentage < 0)
        //         rateString = rateString + '<span style="color:red"><img src="assets/image/icon-trending down.svg">&nbsp;' + percentage.toFixed(0) + '%</span>';
        // }
        // return this.sanitizer.bypassSecurityTrustHtml(rateString);
        // // {{d.yourRate}}&nbsp; {{getSign(d)}}

        return rateString;
    }

    async createChart() {
        let benchmark = this.soc['socs']['soc'];
        let current = this.pyramidData;
        _.forEach(current, (data) => {
            if (benchmark[data['name']] != data['levelValue']) {
                this.isDefaultValuesChanged = true;
            }
        })
        var options1 = {
            series: [

                {
                    name: 'Benchmark',
                    data: [0, 0, 0, 0, -benchmark['level1']
                    ]
                },
                {
                    name: 'Benchmark',
                    data: [0, 0, 0, -benchmark['level2'], 0
                    ]
                },
                {
                    name: 'Benchmark',
                    data: [0, 0, -benchmark['level3'], 0, 0
                    ]
                },
                {
                    name: 'Benchmark',
                    data: [0, -benchmark['level4'], 0, 0, 0
                    ]
                },
                {
                    name: 'Benchmark',
                    data: [-benchmark['level5'], 0, 0, 0, 0
                    ]
                }
            ],
            chart: {
                type: 'bar',
                fontFamily: 'Open Sans',
                height: 200,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
            },
            legend: {
                show: false,
                position: 'top',

            },
            tooltip: {
                enabled: false
            },
            colors: ['#DCCD83', '#FFE875', '#F2D333', '#D8B402', '#B89D12'].reverse(),
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '80%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                        maxItems: 10,
                        hideOverflowingLabels: false
                    }
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    if (Math.abs(val) > 0)
                        return Math.abs(val) + "%";
                    else
                        return '';
                },
                offsetX: 20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                },
            },
            stroke: {
                width: 1,
                colors: ["#fff"]
            },

            grid: {
                xaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                show: false,
                categories: ['L5', 'L4', 'L3', 'L2', 'L1'],
                title: {
                    text: 'Benchmark SOC'
                },
                labels: {
                    formatter: function (val) {
                        return Math.abs(Math.round(val)) + "%"
                    }
                }
            },
            yaxis: {
                min: -Math.max(benchmark['level5'], benchmark['level4'], benchmark['level3'], benchmark['level2'], benchmark['level1']) - 10,
                max: 0,
                show: false,
                opposite: true,
                dataLabels: {
                    show: false,
                },
                labels: {
                    show: false,
                }

            },
            responsive: [
                {
                    breakpoint: 1400,
                    options: {
                        chart: {
                            width: "100%",
                            height: 175,
                        },

                    }
                },
                {
                    breakpoint: 1600,
                    options: {
                        chart: {
                            width: "100%",
                            height: 210,
                        },

                    }
                },
                {
                    breakpoint: 1900,
                    options: {
                        chart: {
                            width: "100%",
                            height: 210,
                        },

                    }
                },
                {
                    breakpoint: 2400,
                    options: {
                        chart: {
                            width: "100%",
                            height: 230,
                        },

                    }
                }

            ]
        };
        var options2 = {
            series: [
                {
                    name: 'Current',
                    data: [0, 0, 0, 0,
                        current[4].levelValue
                    ]
                },
                {
                    name: 'Current',
                    data: [0, 0, 0,
                        current[3].levelValue, 0
                    ]
                },
                {
                    name: 'Current',
                    data: [0, 0,
                        current[2].levelValue, 0, 0
                    ]
                },
                {
                    name: 'Current',
                    data: [0,
                        current[1].levelValue, 0, 0, 0
                    ]
                },
                {
                    name: 'Current',
                    data: [
                        current[0].levelValue, 0, 0, 0, 0
                    ]
                }
            ],
            chart: {
                type: 'bar',
                fontFamily: 'Open Sans',
                height: 200,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
            },
            tooltip: {
                enabled: false
            },
            legend: {
                show: false
            },
            colors: ['#C6D5E1', '#BFEDFF', '#6E9DD7', '#5780B2', '#40628d'].reverse(),
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '80%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                        maxItems: 10,
                        hideOverflowingLabels: false
                    }
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    if (Math.abs(val) > 0)
                        return Math.abs(val) + "%";
                    else
                        return '';
                },
                offsetX: 20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            stroke: {
                width: 1,
                colors: ["#fff"]
            },

            grid: {
                xaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                show: false,
                categories: ['L5', 'L4', 'L3', 'L2', 'L1'
                ],
                offsetX: 10,
                title: {
                    text: 'Input SOC'
                },
            },
            yaxis: {
                min: 0,
                max: Math.max(benchmark['level5'], benchmark['level4'], benchmark['level3'], benchmark['level2'], benchmark['level1']) + 10,
                labels: {
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontWeight: 700
                    },
                },
                tooltip: {
                    enabled: false
                },
            },
            responsive: [
                {
                    breakpoint: 1400,
                    options: {
                        chart: {
                            width: "100%",
                            height: 175,
                        },
                    }
                },
                {
                    breakpoint: 1600,
                    options: {
                        chart: {
                            width: "100%",
                            height: 210,
                        },

                    }
                },
                {
                    breakpoint: 1900,
                    options: {
                        chart: {
                            width: "100%",
                            height: 210,
                        },
                    }
                },
                {
                    breakpoint: 2400,
                    options: {
                        chart: {
                            width: "100%",
                            height: 230,
                        },

                    }
                }
            ]
        };

        if (this.chart1 != undefined) {
            this.chart1.updateOptions(options1)
        }
        else {
            this.chart1 = new ApexCharts(document.querySelector("#chart1_" + this.identifier), options1);
            this.chart1.render();

        }

        if (this.chart2 != undefined) {
            this.chart2.updateOptions(options2)
        }
        else {
            this.chart2 = new ApexCharts(document.querySelector("#chart2_" + this.identifier), options2);
            this.chart2.render();

        }
        let options3 = Object.assign({}, options1)
        let options4 = Object.assign({}, options2)
        let responsive = [
            {
                breakpoint: 1000,
                options: {
                    chart: {
                        width: '800',
                        height: 300,
                    },
                }
            }
        ];
        options3['responsive'] = responsive;
        options4['responsive'] = responsive;
        options3.chart.height = 300;
        options4.chart.height = 300;
        this.emitFinalSOCData.emit({ tower: this.soc, data: this.data, options: { options1: options3, options2:options4 } })
        if(!this.pdfExport){
            let towername = this.soc.tower; 
            if(localStorage.getItem('pyramidRuntime')){
                let data:any = localStorage.getItem('pyramidRuntime');
                data = JSON.parse(data);
                data[towername] = { tower: this.soc, data: this.data, options: { options1: options3, options2:options4 } };
                localStorage.setItem('pyramidRuntime', JSON.stringify(data))
            }
            else
                localStorage.setItem('pyramidRuntime', JSON.stringify({ [towername]: { tower: this.soc, data: this.data, options: { options1: options3, options2:options4 } } }))
        }
    }
}
