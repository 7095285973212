<div class="white-header">
  <div class="white-header-inner">
    <div class="sub-menubread">
      <ul class="sub-menubread-child">
        <li>
          <a href="/">
            <i class="icons-image icon-dashboard" aria-hidden="true"></i>
            Dashboard
          </a>
        </li>
        <li>
          <a href="javascript:void(0)">
            <i class="icons-image icon-chevron-right angle-bread" aria-hidden="true"></i>
            <ng-container *ngIf="loadedPageType != 'ru'"><span matTooltip="Benchmark data for FTE based pricing">FTE
                Benchmark</span></ng-container>
            <ng-container *ngIf="loadedPageType == 'ru'"><span
                matTooltip="Benchmark data for Resource Unit based pricing">RU
                Benchmark</span></ng-container>
          </a>
        </li>
      </ul>
    </div>
    <div class="toggle-btn-wrap tour-options" *ngIf="loadedPageType != 'ru'">
      <a routerLink="/fte/role-level" (click)="analysisType = 'role-level'"
        [ngClass]="{'active': analysisType == 'role-level'}" class="toggle-btn">
        <span matTooltip="Benchmark role and location specific FTE rates">Discrete Role Benchmarking</span></a>
      <a routerLink="/fte/blended-rate" (click)="analysisType = 'blended-rate'"
        [ngClass]="{'active': analysisType == 'blended-rate'}" class="toggle-btn">
        <span matTooltip="Benchmark blended FTE rates at Tower level">Blended Rate Benchmarking</span></a>
    </div>
    <div class="button-list">
      <ng-container *ngIf="isDownloadInProgress">
        <span class="downloadInProgress" style="font-size: 12px;">Downloading..</span>
        <img style="width: 25px;" src="../../assets/img/dark-loader.gif" alt="" class="loader downloadInProgress">
      </ng-container>

      <ul>
        <li class="download-btn">
          <button  class="download-tour" mat-stroked-button [matMenuTriggerFor]="downloadAs"
            [matTooltipPosition]="'left'" matTooltip="Download Benchmark data">
            <span class="download-btn-span">Download </span>
            <span class="download-icon-small-screen"><i class="icons-image  download-icon-show" aria-hidden="true"></i>
              <i class="icons-image icon-arrow-down" aria-hidden="true"></i>
            </span>
          </button>
          <mat-menu class="download-as" #downloadAs="matMenu" style="min-height: auto;">
            <button [disabled]="isDownloadInProgress" mat-menu-item (click)="download()">
              <img class="me-2" src="../../assets/image/excelbtn.svg" width="25" alt="icon">
              <span>Benchmark Data</span>
            </button>
            <button [disabled]="isDownloadInProgress" mat-menu-item (click)="downloadPDFReport()">
              <img class="me-2" style="margin-left:-4px;" src="../../assets/image/pdfbtn.svg" width="30" alt="icon">
              <span>PDF Report</span>
            </button>
            
          </mat-menu>
        </li>
        <li (click)="openDialog()" class="save-btn">
          <button mat-stroked-button matTooltip="Save the current benchmark scenario">
            <span class="download-btn-span">Save Scenario </span>
            <i class="icons-image icon-save-new" aria-hidden="true"></i>
          </button>
        </li>
        <li class="reset-btn">
          <button matTooltip="Reset" (click)="openResetDialog()" mat-stroked-button>
            <span class="download-btn-span">Reset </span>
            <i class="icons-image icon-reset-new" aria-hidden="true"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="inner-div-dashbord" [ngClass]="{'pr-45': isTwoColumn}">
  <div class="btn-vertical-wrap" [ngClass]="{'only-selection-btn': !sPane && oAPane, 'only-oa-btn': !oAPane && sPane}">
    <button (mouseover)="showButtonSelections = true" (mouseleave)="showButtonSelections = false"
      (click)="togglePaneS()" [ngClass]="{'d-none': sPane}" class="btn-vertical tour-selections">
      <span>Selections</span>
      <i class="icons-image icon-double-arrow-white" aria-hidden="true"></i>
    </button>
    <button (mouseover)="showButton = true" (mouseleave)="showButton = false" (click)="togglePaneOA()"
      [ngClass]="{'d-none': oAPane}" class="tour-opportunity btn-vertical">
      <span>Opportunity Sizing</span>
      <i class="icons-image icon-double-arrow-white" aria-hidden="true"></i>
    </button>
  </div>
  <div class="tour-wrap" *ngIf="showButton" [ngStyle]="{'top': (!sPane == true) ? '250px' : '100px' }">
    <div class="tour">
      <div class="tour-desc">Perform an assessment of your spend in comparision to the benchmark spend.</div>
    </div>
  </div>
  <div class="tour-wrap" *ngIf="showButtonSelections" style="top: 100px;">
    <div class="tour">
      <div class="tour-desc">View/Update selections made to view the benchmark data</div>
    </div>
  </div>
  <div class="row m0">
    <div *ngIf="sPane" [ngClass]="{'col-md-6': isTwoColumn && sPane}" class="p0">
      <div class="left-col-wrap">
        <div class="right-col-head">
          <span>Selections</span>
          <a (click)="togglePaneS()" class="d-flex" href="javascript:void(0)">
            <i class="icons-image icon-cancel" aria-hidden="true"></i>
          </a>
        </div>
        <div class="left-col-inner">
          <div class="heading-wrap">
            <div class="headings">Towers <i class="icons-image icon-info-light-gray"
                matTooltip="Select relevant functional towers and sub-towers (refer to list of towers and sub-towers)"></i>
            </div>
            <a href="javascript:void(0)" (click)="openTSSelectorDialog()" matTooltip="Open view">
              <i class="icons-image icon-expand" aria-hidden="true"></i>
            </a>
          </div>
          <div class="category-wrap">
            <app-donut-chart-tower [variant]="'mini'" [isTwoColumn]="isTwoColumn"></app-donut-chart-tower>
          </div>
          <div class="region-main">
            <div class="heading-wrap">
              <div class="headings">Locations <i class="icons-image icon-info-light-gray"
                  matTooltip="Select the relevant delivery location"></i>
              </div>
              <a href="javascript:void(0)" (click)="openLocationSelectorDialog()" matTooltip="Open view">
                <i class="icons-image icon-expand" aria-hidden="true"></i>
              </a>
            </div>
            <div class="region-content scroll-window" (click)="openLocationSelectorDialog()">
              <div class="region-overlay">
                <a href="javascript:void(0)" class="btn-model">Click to open</a>
              </div>
              <app-region-chart [loadedPageType]="loadedPageType" [variant]="'mini'" [dashboardView]="true"
                [isModal]="false"></app-region-chart>
            </div>
          </div>
          <div class="provider-main">
            <app-provider-types></app-provider-types>
          </div>
          <div *ngIf="loadedPageType == 'ru'">
            <app-deal-params></app-deal-params>
          </div>
        </div>
      </div>
    </div>
    <div class="p0" [ngClass]="{'col-md-6': isTwoColumn, 'col-md-8 offset-md-2': isOneColumn}">
      <div class="right-col-wrap">
        <div class="right-col-head">
          <span *ngIf="loadedPageType == 'role-level'">Role based FTE Benchmark Rates</span>
          <span *ngIf="loadedPageType == 'blended-rate'">Tower based Blended FTE Benchmark Rates</span>
          <span *ngIf="loadedPageType == 'ru'">Resource Unit based Benchmark Rates</span>
        </div>
        <div class="right-col-inner">
          <div class="price-main">
            <app-general-price-bar *ngIf="loadedPageType != 'blended-rate'" class="role-level-price-bar"
              [type]="'role-level'"></app-general-price-bar>
            <mat-tab-group *ngIf="loadedPageType == 'blended-rate'">
              <mat-tab>
                <ng-template matTabContent>
                  <app-general-price-bar class="blended-level-price-bar" [type]="'blended-rate'"
                    [hidden]="loadedPageType != 'blended-rate'"> </app-general-price-bar>
                  <span class="p-2 headings">Blended FTE Benchmark Rate using Input SOC <i
                      class="icons-image icon-info-light-gray" [matTooltip]="getTooltip()"
                      matTooltipClass="only-break-word-tooltip"></i></span>
                  <app-tower-analysis></app-tower-analysis>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="oAPane" [ngClass]="{'col-md-6': isTwoColumn && oAPane}" class="p0">
      <div class="right-col-wrap" *ngIf="loadedPageType == 'role-level'">
        <div class="right-col-head">
          <span>Opportunity Sizing</span>
          <a (click)="togglePaneOA()" class="d-flex" href="javascript:void(0)">
            <i class="icons-image icon-cancel" aria-hidden="true"></i>
          </a>
        </div>
        <div class="right-col-inner">
          <div class="row hex-role-table">
            <div class="col-12 pe-0 ps-0">
              <div class="editbutton-col" style="justify-content: left;line-height: 1;">
                <span class="headings px-0 py-1 pe-4">Role based Headcount and Spend Details</span>
              </div>
            </div>
          </div>
          <app-roles-list> </app-roles-list>
          <div class="savings-main " style="height:200px">
            <app-savings-analysis-role></app-savings-analysis-role>
          </div>
        </div>
      </div>
      <div class="right-col-wrap" *ngIf="loadedPageType == 'blended-rate'">
        <div class="right-col-head">
          <span>Opportunity Sizing</span>
          <a (click)="togglePaneOA()" class="d-flex" href="javascript:void(0)">
            <i class="icons-image icon-cancel" aria-hidden="true"></i>
          </a>
        </div>
        <div class="right-col-inner">
          <div class="row hex-role-table">
            <div class="col-12 ps-0 pe-0">
              <div class="editbutton-col" style="justify-content: left;line-height: 1;">
                <span class="headings px-0 py-1 pe-4">Tower based Headcount
                  and Spend Details</span>
              </div>
            </div>
          </div>
          <app-opportunity-assessment-new></app-opportunity-assessment-new>
          <div class="savings-main">

            <app-savings-analysis-tower></app-savings-analysis-tower>
          </div>
        </div>
      </div>

      <div class="right-col-wrap" *ngIf="loadedPageType == 'ru'">
        <div class="right-col-head">
          <span>Opportunity Sizing</span>
          <a (click)="togglePaneOA()" class="d-flex" href="javascript:void(0)">
            <i class="icons-image icon-cancel" aria-hidden="true"></i>
          </a>
        </div>
        <div class="right-col-inner">
          <div class="row hex-role-table">
            <div class="col-12 pe-0 ps-0">
              <div class="editbutton-col">
                <span class="headings hex-role-table">RU based Volume and Spend Details</span>
              </div>
            </div>
          </div>
          <app-roles-list> </app-roles-list>
          <!-- <app-opportunity-assessment-new></app-opportunity-assessment-new> -->
          <div class="savings-main">
            <app-savings-analysis-role></app-savings-analysis-role>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<iframe name="pdfExport" width="100%" [src]="safeUrl" *ngIf="safeUrl"></iframe>
