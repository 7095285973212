import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { db } from 'src/db';
import { DataService } from '../data.service';
declare let download: any;
import * as _ from 'lodash';
import { config } from 'src/environments/config';
import { InteractiveDashboardComponent } from '../interactive-dashboard/interactive-dashboard.component';
import { DataUpdateService } from '../data-update.service';
import { formatNumber } from '@angular/common';
import { SavingsAnalysisService } from '../services/savings-analysis.service';
import { forkJoin } from 'rxjs';
import { MillionPipe } from '../million.pipe';
import { draw } from 'patternomaly'

@Component({
  selector: 'app-pdf-export-view',
  templateUrl: './pdf-export-view.component.html',
  styleUrls: ['./pdf-export-view.component.scss'],
  providers: [MillionPipe]
})
export class PdfExportViewComponent implements OnInit {

  constructor(private router: Router, private apiService: ApiService,
    private route: ActivatedRoute, private dataService: DataService,
    private savingAnalysisService: SavingsAnalysisService,
    private savingsService: SavingsAnalysisService,
    private millionPipe: MillionPipe) {}
  loadedPageType = '';
  analysisType = '';
  isRuEnabled: boolean = true;
  type: string = 'role-level';
  @ViewChild('allpageContent', { static: false }) el!: ElementRef
  loadedComponentType: string = '';
  skillFactor = 1;
  masterSelections: any;
  roles = [];
  benchmarkCalculations: any;
  towerSelections: any;
  socData: any = {};
  apexCharts: any = {};
  clientDetails: any;
  selectedTowers = [];
  savingsData = [];
  detailedAnalysisSavingsData = [];
  detailedTowerGraphs = {};
  benchmarkPoint = "50";

  ngOnInit(): void {
    if(localStorage.getItem('benchmarkPoint')){
      this.benchmarkPoint = localStorage.getItem('benchmarkPoint');
    }
    this.route.params.subscribe(params => {
      this.loadedPageType = params['type'];
      this.type = params['type'];
      this.analysisType = params['type'];
      if (this.loadedPageType != 'ru')
        this.isRuEnabled = false
      else
        this.isRuEnabled = environment.isRUEnabled && environment.production;
      this.getVarianceData();
    });
    this.apiService.getClientDetails().subscribe(details => {
      this.clientDetails = details;
    })
    this.postData();

  }

  async getTowerSubtowerData() {
    let selections = await this.dataService.getSelections(false);
    this.masterSelections = selections;
    return selections
  }

  async getRowDatas() {
    let roles = [];
    switch (this.type) {
      case 'role-level':
        this.loadedComponentType = 'role';
        let data: any = await this.getRoles()
        //this.getRoles().then(async (data) => {
        this._getMinMaxRangeForLocationProvider(data);
        let locs = Array.from(new Set(data.flatMap(e => e["rates"]).map(e => e.location)));
        let experiences = _.uniqBy(data, 'roleData.experience').map(role => { if (role.roleData) return role.roleData.experience });
        experiences = _.compact(experiences);
        roles = data.filter(role => { return role.isSelected })
        if (roles.length == 0) {
          roles = data;
        }
        //})
        break;
      case 'ru':
        this.loadedComponentType = 'role';
        let rudata: any = await this.getRoles()
        //this.getRoles().then(async (data) => {
        this._getMinMaxRangeForLocationProvider(rudata);
        let rulocs = Array.from(new Set(rudata.flatMap(e => e["rates"]).map(e => e.location)));
        let ruexperiences = _.uniqBy(rudata, 'roleData.experience').map(role => { if (role.roleData) return role.roleData.experience });
        ruexperiences = _.compact(ruexperiences);
        roles = rudata.filter(role => { return role.isSelected })
        //})
        if (roles.length == 0) {
          roles = rudata;
        }
        break;
      case 'blended-rate':
        this.loadedComponentType = 'tower';
        let towerdata = await this.getTowers()
        this._getMinMaxRangeForLocationProvider(towerdata, 'towers')
        roles = towerdata;
        break;
    }
    return roles;
  }

  async getTowers() {
    return await this.dataService.getTowerWithRatesInputPlot();
  }

  async getTowersWithDetailedAnalysis() {
    let detailedAnalysis = await this.dataService.getTowerWithDetailedAnalysis();
    let dataRequests = []
    detailedAnalysis.forEach(async (tower) => {
      dataRequests.push(this.savingAnalysisService.calculateValues(tower.id))
    })
    forkJoin(dataRequests).subscribe(data => {
      this.detailedAnalysisSavingsData = data
      this.detailedAnalysisSavingsData.forEach(tower => {
        this.detailedTowerGraphs[tower.towerId] = this.plotChart(tower)
      })
    })
    return detailedAnalysis;
  }


  async getRoles() {
    return await this.dataService.getRolesWithRatesInputPlot()
  }

  private _getMinMaxRangeForLocationProvider(data, mode = 'roles') {
    let minMaxRoles = {};
    let rates = {};
    _.forEach(data, (item) => {
      _.forEach(item.rateSPT, (rate, location) => {
        if (!rates[location])
          rates[location] = {}
        _.forEach(rate, (values, spid) => {
          if (!rates[location][spid])
            rates[location][spid] = {}
          _.forEach(values, (rate, bp) => {
            if (!rates[location][spid][bp])
              rates[location][spid][bp] = []
            rates[location][spid][bp].push(rate)
          })
        })
      })
    })
    _.forEach(rates, (rate, location) => {
      minMaxRoles[location] = {}
      _.forEach(rate, (values, sp) => {
        minMaxRoles[location][sp] = {
          min: _.min(values[25]) - 5,
          max: _.max(values[75]) + 5,
          buckets: (() => {
            let arr = [],
              min = _.min(values[25]) - 5,
              max = _.max(values[75]) + 5,
              i,
              buckets = 3;
            for (i = min; i <= max; i += (max - min) / buckets) {
              arr.push(i);
            }
            return arr;
          })()
        }
      })
    })
    return minMaxRoles;
  }

  convertSvgToPng(selector) {
    var svg = document.querySelector(selector);
    var xml = new XMLSerializer().serializeToString(svg);
    var svg64 = btoa(xml);
    var b64Start = 'data:image/svg+xml;base64,';
    b64Start = 'data:image/gif;base64,';
    var image64 = b64Start + svg64;
    return image64;
  }

  async postData() {
    let selectedFilters = {
      discrete: "Discrete Spend",
      role: "Role Spend",
      total: "Total Spend",
      location: "Location Spend",
      tower: "Tower Spend"

    }

    let sunburstImage = '';
    let regionImage = '';
    this.towerSelections = await this.dataService.getPyramidChartData();

    /* deal params */
    let dealParamsData = await this.dataService.getDataFromDb('deal_params', {});
    let selectedCategory = await db.selection_categories.toArray();


    dealParamsData = dealParamsData.filter(dp => { return dp.category == selectedCategory[0]['id'] });
    let params = _.groupBy(dealParamsData, _.iteratee('parameter'));
    let resultArray = [];

    _.forEach(params, (value, key) => {
      let selections = value.filter(v => { return v.isSelected })
      if (selections.length == 0) {
        selections = value.filter(v => { return v.isDefault })
      }
      if (key == 'Offshore Leverage') {
        selections = [selections[0]]
      }
      resultArray.push({
        paramName: key,
        values: selections
      });
    });

    /* Tower Params */

    let towerParams = await this.dataService.getTowerParams()

    setTimeout(async () => {
      sunburstImage = this.convertSvgToPng('#towerSunburst');
      regionImage = this.convertSvgToPng('#map svg')
      //towerBenchmarkpng = this.convertSvgToPng('#savingsRoleTower svg')
      //locationBenchmarkpng = this.convertSvgToPng('#savingsRoleLocation svg')

      let selections = await this.getTowerSubtowerData();
      let boxPlotData = await this.getRowDatas();
      let filters = await db.role_filter.toArray();
      let providerOpp = filters.filter(f => f.type == 'spt')[0]['value']
      let benchmarkPoint = 50;
      this.savingsService.getBenchmarkPoint().subscribe(res => {
        benchmarkPoint = res;
        console.log(res)
      })

      if(this.loadedPageType == 'role-level' || this.loadedPageType == 'ru'){
        filters = filters.filter(f => f.type == 'role spend')
      }
      let spendType = selectedFilters[filters[0]['value']]
      let spends =  await db.spends.toArray();
      let spendsData = {};
      
      if(filters[0]['value'] == 'role'){
        //spends = await db.spends.toArray();
        let sp = spends.filter(sp => { return sp.location == 0 && sp['spendSelection'] == 'role'})
        sp.forEach(sp => {
          spendsData[sp.role] = sp.spend;
        })
      }

      if(filters[0]['value'] == 'location'){
        let sp = spends.filter(sp => { return sp.role == 0 && sp['spendSelection'] == 'location'})
        sp.forEach(sp => {
          spendsData[sp.location] = sp.spend;
        })
      }

      if(filters[0]['value'] == 'total'){
        let sp = spends.filter(sp => { return sp.role == 0 && sp['spendSelection'] == 'total' && sp.location == 0})
        sp.forEach(sp => {
          spendsData['total'] = sp.spend;
        })
      }

      if(filters[0]['value'] == 'tower'){
        let sp = spends.filter(sp => { return sp['spendSelection'] == 'tower' && typeof sp.location === 'undefined' })
        sp.forEach(sp => {
          spendsData[sp.tower] = sp.spend;
        })
      }
      let opportunityData = await this.getTowersWithDetailedAnalysis();
      opportunityData.forEach(async(tower) => {
        let savingsData = await this.savingAnalysisService.calculateValues(tower['id']);
        tower['detailedAnalysis'].forEach(ele => {
            ele['benchmarkSpend'] = savingsData['targetSpendByLocation'].get(ele.location)
        })
      })

      if(this.loadedPageType == 'blended-rate'){
        
      }
      //console.log(this.socData, JSON.parse(localStorage.getItem('pyramidRuntime')))

      let socDataFinal = _.merge(this.socData, JSON.parse(localStorage.getItem('pyramidRuntime')))
      let selectedTowers = await db.selection_towers.toArray();
      let selectedTowerNames = selectedTowers.map(tower => {return tower.tower })
      _.forEach(socDataFinal, (value, key) => {
        if(selectedTowerNames.indexOf(key) == -1){
          delete socDataFinal[key]
        }
      })
      
      let requestData = {
        loadedPageType: this.loadedPageType,
        loadedType: this.type,
        selections,
        boxPlotData,
        opportunityData: opportunityData,
        opportunitySelections: {
          spendType,
          spendSelection: filters[0]['value'],
          provider: selections.provider.filter(prov => { return prov.id == providerOpp})[0],
          spends,
          spendsData
        },
        benchmarkCalculations: this.benchmarkCalculations,
        benchmarkSelections: {
          quartile: benchmarkPoint
        },
        dealParamsData: resultArray,
        towerParams: towerParams,
        //socData: this.socData,
        socData: socDataFinal,
        clientDetails: this.clientDetails,
        images: {
          sunburstImage,
          regionImage
        },
        apexCharts: this.apexCharts,
        detailedTowerGraphs: this.detailedTowerGraphs
      }
      requestData['blendedDetailLevel'] = await this.getBlendedDetailData();
      requestData['roleData'] = await this.getRoles();
      let towers = selections['tower'].map(tower => { return tower.id })
      let socs = await db.socs.where('towerid').anyOf(towers).toArray();
      requestData['blendedSoc'] = socs;
      boxPlotData.forEach((rec) => {
        delete rec['rates']
      })

      this.apiService.downloadPDFReport(requestData);
    }, 4000);
  }

  async getVarianceData() {
    this.skillFactor = await this.dataService.getSkillFactor();
    let callback = async (data) => {
      let result = this.type == 'tower' ? await this.getChartDataTower(this.skillFactor) : await this.getChartDataRole(this.skillFactor);

    };

    if (this.type == 'role-level')
      this.getRoles().then(callback.bind(this))
    else if (this.type == 'blended-rate')
      this.getTowers().then(callback.bind(this))
  }

  async getChartDataRole(skillFactor: number) {

    let data = await this.dataService.getRolesWithRatesInputPlot();
    const selectedLocations = await this.getSelectedLocations();
    let locationIds = selectedLocations.map(loc => { return loc['id'] });

    const selectedSPT = await this.getSelectedSPT();
    let selectedArea = await this.dataService.getDataFromDb('areas', {}, true);

    const inputs = await this.getSelectedInputs('role');
    const towersDetail = await this.dataService.getDataFromDb('towers', { areaId: selectedArea[0]['id'] }, true); // await db.selection_towers.toArray();

    const roles = [...new Set(inputs.map(item => item.role))];
    const rolesDetail = await db.roles.where('id').anyOf(roles).toArray();
    this.roles = rolesDetail.map(role => `${role.role} (${towersDetail.find(tower => tower.id == role.tower)['shortCode']})`);

    const rates = await this.getRates(roles, 'role');

    const towers = Object.fromEntries(towersDetail.map(({ id, tower }) => ([id, tower])));

    let dataset = [];

    selectedLocations.forEach(location => {
      selectedSPT.forEach(spt => {
        let series = {
          name: location.locationName + "(" + spt.spt.substring(0, 1) + ")",
          data: [],
          metadata: []
        }
        let flag = 0;
        roles.forEach(async role => {
          let input = inputs.filter(input => input.role == role && input.serviceProvider == spt.id && input.location == location.id);
          let obj = {};
          obj['location'] = location.locationName;
          obj['spt'] = spt.spt;
          obj['spt_id'] = spt.id;
          obj['role'] = this.roles[role];
          obj['tower'] = towers[rolesDetail.filter(x => x.id == role)[0].tower];
          obj['x'] = flag++;
          if (input.length > 0) {
            obj['input'] = config.formatNumber(input[0].inputValue);
            // let encrptedRate = rates.filter(rate => rate['role'] == role && rate['serviceProviderType'] == spt.id && rate['location'] == location.id && rate['benchmarkPoint'] == 50)[0]['value'];
            // let rate = parseFloat(this.encryptDecryptService.decryptData(encrptedRate)) * skillFactor;
            let roleRate = data.filter(x => x.id == role)[0]['rateSPT'][location.id][spt.id][50];

            let variance = ((input[0].inputValue - roleRate) / roleRate) * 100;
            obj['median'] = config.formatNumber(roleRate);
            obj['y'] = parseInt(variance.toFixed(0));
            obj['variance'] = config.formatNumber(variance);
          }
          else
            obj['y'] = '';
          series.metadata.push(obj);
          series.data.push(obj['y'])
        });
        if (series.metadata.filter(x => x.y != '').length > 0)
          dataset.push(series);
      })
    })

    return dataset;
  }

  async getChartDataTower(skillFactor: number) {

    let data = await this.dataService.getTowerWithRatesInputPlot();
    const selectedLocations = await this.getSelectedLocations();
    let locationIds = selectedLocations.map(loc => { return loc['id'] });

    const selectedSPT = await this.getSelectedSPT();

    const inputs = await this.getSelectedInputs('tower');

    const towerIds = [...new Set(inputs.map(item => item.tower))];
    const towersDetail = await db.towers.where('id').anyOf(towerIds).toArray();

    const towersList = Object.fromEntries(towersDetail.map(({ id, tower }) => ([id, tower])));
    this.roles = towersDetail.map((tower) => tower.tower);

    const rates = await this.getRates(towerIds, 'tower');

    let dataset = [];

    selectedLocations.forEach(location => {
      selectedSPT.forEach(spt => {
        let series = {
          name: location.locationName + "(" + spt.spt.substring(0, 1) + ")",
          data: [],
          metadata: []
        }
        let flag = 0;
        towerIds.forEach(async tower => {
          let input = inputs.filter(input => input.tower == tower && input.serviceProvider == spt.id && input.location == location.id);
          let obj = {};
          obj['location'] = location.locationName;
          obj['spt'] = spt.spt;
          obj['spt_id'] = spt.id;
          obj['tower'] = towersList[tower];
          obj['x'] = flag++;
          if (input.length > 0) {
            obj['input'] = config.formatNumber(input[0].inputValue);
            // let towerRates = rates.filter(rate => rate['location'] == location.id && rate['tower'] == tower && rate['benchmarkPoint'] == 50).map(x => parseFloat(x['intValue']));
            // const average = towerRates.reduce((prev, curr) => prev + curr) / towerRates.length;
            let towerRate = data.filter(x => x.id == tower)[0]['rateSPT'][location.id][spt.id][50];
            // let rate = average * skillFactor;
            let variance = ((input[0].inputValue - towerRate) / towerRate) * 100;
            obj['median'] = config.formatNumber(towerRate);
            obj['y'] = variance.toFixed(0);
            obj['variance'] = config.formatNumber(variance);
          }
          else
            obj['y'] = '';
          series.metadata.push(obj);
          series.data.push(obj['y'])
        });
        if (series.metadata.filter(x => x.y != '').length > 0)
          dataset.push(series);
      })
    })

    return dataset;
  }

  async getSelectedLocations() {
    return this.dataService.getDataFromDb('locations', {}, true);
  }

  async getSelectedSPT() {
    return this.dataService.getDataFromDb('providerTypes', {}, true);
  }

  async getSelectedInputs(col) {
    return await db.input_plot.where(col).notEqual('').toArray();
  }

  async getRates(ids, col) {
    return await db.rateList.where(col).anyOf(ids).toArray();
  }

  bindCalculations(e) {
    this.benchmarkCalculations = e;
  }

  storeTowerData(e) {
    this.socData[e.tower.tower] = e;
  }

  SavingAnalysisGraphOptions(e, type) {
    this.apexCharts[type] = e;
  }

  async getBlendedDetailData() {
    let rates = await this.getRatesBlendedAnalysis();
    return await this.getLevelData(rates);
  }

  async getRatesBlendedAnalysis() {
    let sf = await this.dataService.getSkillFactor();
    let subtowers = await db.selection_subtowers.toArray();
    let rates = await db.rateList.where('subTower').anyOf(subtowers.map(st => { return st.id })).toArray();
    this.roles = _.groupBy(rates, _.iteratee('roleFTE.role'));
    subtowers = _.groupBy(subtowers, _.iteratee('id'))
    return rates.map(r => { r['intValue'] *= sf; return r; });
  }

  async getLevelData(rates) {
    let final = this._deepGroup(rates, ['tower', 'location', 'level', 'serviceProviderType', 'benchmarkPoint'])
    _.forEach(final, (towervalue, tower) => {
      _.forEach(towervalue, (locationValue, location) => {
        _.forEach(locationValue, (value, level) => {
          _.forEach(value, (valu2, sp) => {
            _.forEach(valu2, async (bp, sp2) => {
              final[tower][location][level][sp][sp2] = _.mean(bp.map(b => { return b.intValue })).toFixed(0)
              final[tower][location][level]['maximiseLevel'] = await this.maximiseLevel(level, rates, tower, location);
            })
          })
        })
      });
    });
    return final;
  }

  private _deepGroup = function (seq, keys) {
    if (!keys.length) return seq;
    var first = keys[0];
    var rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => { return this._deepGroup(value, rest) });
  };

  async maximiseLevel(level, rates, tower, location) {
    rates = rates.filter(r => { return r['level'] == level && r['tower'] == tower && r['location'] == location })
    let final = this._deepGroup(rates, ['subTower', 'roleFTE.role', 'serviceProviderType'])
    _.forEach(final, (value, role) => {
      _.forEach(value, (valu2, sp) => {
        _.forEach(valu2, (rates, sp2) => {
          let r = {};
          _.forEach(rates, (rate, k) => {
            r[rate.benchmarkPoint] = rate.intValue.toFixed(0);
          });
          final[role][sp][sp2] = r;
        })
      })
    });
    return final;
  }

  calculatePercentage(total, value) {
    let percentage: any = ((value - total) / total) * 100;
    percentage = formatNumber(percentage, "en-US", "2.1-2").replace(',', '');
    return percentage;
  }

  detailedAnalysisChart(event, tower) {
    this.detailedTowerGraphs[tower] = event;
  }

  plotChart({ currentSpend, shoringSavings, socSavings, rateSavings, targetSpend }) {

    const annotation1 = {
      type: 'line',
      scaleID: 'y',
      borderWidth: 1,
      borderColor: '#000',
      value: 0,
    };
    const annotation2 = {
      type: 'line',
      scaleID: 'y',
      borderWidth: 2,
      borderColor: '#C5AC2E',
      borderDash: [3, 3],
      value: targetSpend.total,
    };

    let spendDataset: any[] = [
      {
        label: 'Benchmark Spend',
        backgroundColor: "#C5AC2E",
        maxBarThickness: 100,
        data: [0, 0, 0, 0, targetSpend.total]
      }
    ];

    if (currentSpend.total != 0) {
      
      spendDataset = [
        {
          label: 'Extra',
          backgroundColor: "#FFF",
          maxBarThickness: 0,
          data: [
            0,
            (currentSpend.total - Math.max(shoringSavings.total, 0)),
            (currentSpend.total - shoringSavings.total - Math.max(socSavings.total, 0)),
            (currentSpend.total - shoringSavings.total - socSavings.total - Math.max(rateSavings.total, 0)),
            0
          ]
        },
        {
          label: 'Your Spend',
          backgroundColor: "#000",
          maxBarThickness: 100,
          data: [currentSpend.total, 0, 0, 0, 0],
        },
        {
          label: 'Lower than the benchmark',
          backgroundColor: [draw('diagonal', '#737373', '#FFF')],
          maxBarThickness: 100,
          data: [
            0,
            shoringSavings.total < 0 ? Math.abs(shoringSavings.total) : 0,
            socSavings.total < 0 ? Math.abs(socSavings.total) : 0,
            rateSavings.total < 0 ? Math.abs(rateSavings.total) : 0,
            0
          ],
        },
        {
          label: 'Higher than the benchmark',
          backgroundColor: "#A0A0A0",
          maxBarThickness: 100,
          data: [
            0,
            shoringSavings.total >= 0 ? shoringSavings.total : 0,
            socSavings.total >= 0 ? socSavings.total : 0,
            rateSavings.total >= 0 ? rateSavings.total : 0,
            0
          ]
        },
        ...spendDataset]
    }

    return this.getDetailedGraphOptions(spendDataset, annotation1, annotation2);
  }

  getDetailedGraphOptions(dataset: any[], annotation, annotation1){
    let chartOptions = {
      type: 'bar', //this denotes the type of chart
      data: {
        labels: ["Your Spend", "Shoring", "Pyramid", "Rates", "Benchmark Spend"],
        datasets: dataset,
      },

      options: {
        aspectRatio: 4,
        plugins: {
          annotation: {
            annotations: {
              //annotation,
              annotation1
            }
          },
          legend: {
            display: true,
            labels: { filter: (item, chart) => !item.text.includes('Extra'), font: {size: 14} }
          },
          datalabels: {
            color: '#555555',
            align: 'end',
            offset: -5,
            anchor: 'end',
            display: "__LABEL_CALLBACK_PLACEHOLDER__",
            font: {
              weight: 'bold'
            },
            formatter: "__CALLBACK_PLACEHOLDER__"
          }
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            stacked: true,
            type: 'linear',
            ticks: {
              callback: "__CALLBACK_PLACEHOLDER__"
            },
            offset: true
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    };
    console.log(chartOptions)
    return chartOptions
  }

  blendedCalculations(data){
    if(this.loadedPageType == 'blended-rate')
      this.benchmarkCalculations = data;
  }
}
